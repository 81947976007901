<template>
  <div class="time-frame-of-operation-list-page w-100">
    <b-container
      fluid
      class="time-frame-of-operation-list-page__body mb-6 mt-6"
    >
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="card-body mt-0">
              <b-row>
                <b-col>
                  <h5 class="text-success">
                    Thời gian hoạt động thông dụng của bệnh nhân
                  </h5>
                  <template-table
                    :column="columnLeft"
                    :data="dataLeft"
                    :tableAction="false"
                    :selectAction="false"
                    :searchAction="false"
                    :pagingAction="false"
                  >
                    <template v-slot:body="{ item }">
                      <td>
                        {{ item.name }}
                      </td>
                      <td style="width: 200px">
                        <basic-input
                          name="defaultTime"
                          type="time"
                          :disabled="true"
                          :value.sync="item.value"
                        >
                        </basic-input>
                      </td>
                    </template>
                  </template-table>
                </b-col>
                <b-col>
                  <h5 class="text-success">Danh mục khung giờ tương ứng</h5>
                  <template-table
                    :column="columnRight"
                    :data="dataRight"
                    :tableAction="false"
                    :selectAction="false"
                    :searchAction="false"
                    :pagingAction="false"
                  >
                    <template v-slot:body="{ item }">
                      <td>
                        {{ item.name }}
                      </td>
                      <td style="width: 200px">
                        {{ item.value }}
                      </td>
                    </template>
                  </template-table>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pagingLeft: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      pagingRight: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {},
      sort: {
        by: null,
        order: null,
      },
      columnLeft: [
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
        },
        {
          key: 'defaultTime',
          label: 'Giờ mặc định',
          sortable: false,
        },
      ],
      columnRight: [
        {
          key: 'name',
          label: 'Khung thời gian',
          sortable: false,
        },
        {
          key: 'correspondingHour',
          label: 'Giờ tương ứng',
          sortable: false,
        },
      ],
      dataLeft: [],
      dataRight: [],
    };
  },
  computed: {},
  watch: {
    paging: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    sort: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadDataLeft() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('TimeFrame/Default')
        .then(({ meta, data }) => {
          this.dataLeft = data || [];
          this.pagingLeft.total = meta.total;
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
    loadDataRight() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('TimeFrame/List')
        .then(({ meta, data }) => {
          this.dataRight = data || [];
          this.pagingRight.total = meta.total;
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
  },
  mounted() {
    this.loadDataLeft();
    this.loadDataRight();
  },
};
</script>

<style lang="scss">
.time-frame-of-operation-list-page__body {
  .table-responsive {
    max-height: 560px !important;

    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>
